import React from "react";

import Modal from "@mui/material/Modal";

import { cn } from "../../../lib/utils";

type ModalProps = {
  title: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  className?: string;
};

export const MainModal: React.FC<ModalProps> = ({
  title,
  description,
  isOpen,
  onClose,
  children,
  className,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="flex items-center justify-center"
    >
      <div
        className={cn("w-[400px] bg-white rounded-[15px] p-[15px]", className)}
      >
        <h2 className="font-semibold text-[19px]">{title}</h2>
        <p>{description}</p>
        <div className="pt-[20px]">{children}</div>
      </div>
    </Modal>
  );
};
