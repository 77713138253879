import { Link } from "react-router-dom";

import { Button } from "../../components";

export const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-[calc(100vh-20px)]">
      <div className="grid h-screen place-content-center px-4">
        <div className="text-center">
          <h1 className="text-9xl font-black text-white">404</h1>

          <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">Uh-oh!</p>

          <p className="mt-4 text-gray-500">Page not found.</p>

          <Link
            to="/admin/dashboard"
            className="flex justify-center pt-[20px]"
          >
            <Button>Go Back to Main</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
