import React, { useContext, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import AuthContext from "../../context/auth-context";
import FullOpenNavbarContext from "../../context/full-open-navbar";

import { NavbarLinkList } from "./NavbarLinkList";
import { Button } from "../../components";
import { CircleUserRound, X, LogOut, AlignLeft, ChevronRight, ChevronLeft } from "lucide-react";

export const Navbar = () => {
  const { logout } = useContext(AuthContext);
  const { isFullOpened, openFullNavbar, closeFullNavbar } = useContext(FullOpenNavbarContext);
  const [visibleNavbar, setVisibleNavbar] = useState(false);

  const openNavbar = () => setVisibleNavbar(true);
  const closeNavbar = () => setVisibleNavbar(false);

  useEffect(() => {
    const handleResize = () => {
      const body = document.querySelector("body");

      if (body) {
        if (visibleNavbar && window.innerWidth < 1024) {
          body.classList.add("active-body");
        } else {
          body.classList.remove("active-body");
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [visibleNavbar]);

  return (
    <div>
      <div
        onClick={closeNavbar}
        className={`${visibleNavbar && "bg-[#00000080] block fixed z-[2] left-0 top-0 w-full h-[100%] overflow-y-hidden lg:hidden"}`}
      />
      <div className="block py-[10px] lg:hidden">
        <button className="px-[10px] py-[5px]" onClick={openNavbar}>
          <AlignLeft size={18} />
        </button>
      </div>

      <div
        className={`
          nav-bar
          ${visibleNavbar ? "block left-[10px]" : "left-[-400px]"} 
          ${isFullOpened ? "lg:w-[320px]" : "lg:w-[80px] p-[8px]"}
          ease-in-out transition-[1s] w-[320px] bg-white fixed z-[2]
          h-[calc(100dvh-20px)] top-[10px] rounded-[20px] border-[3.5px]
          border-gray-100 p-[14px] flex flex-col justify-between
          lg:block lg:left-[10px] 
        `}
      >
        <button
          className="absolute block top-0 right-0 p-[10px] lg:hidden"
          onClick={closeNavbar}
        >
          <X size={18}/>
        </button>
        <div className="h-[calc(100%-70px)]">
          <div>
            <h2 className="text-center text-[18px] font-semibold">
              <span className={`${isFullOpened ? "lg:block" : "lg:hidden"} text-blue-400`}>
                Admin console
              </span>
              <span
                className={`
                  ${isFullOpened ? "lg:hidden" : "lg:block p-[10px] rounded-[8px] bg-gray-100"} hidden 
                  font-normal text-blue-400
                `}
              >
                A
              </span>
            </h2>
          </div>

          <NavbarLinkList closeNavbar={closeNavbar} />
        </div>

        <button
          className={`
            ${!isFullOpened && "m-auto mt-[-60px]"}
            hidden bg-gray-100 mb-[10px] mt-[-40px] rounded-[8px] p-[8px] lg:block
          `}
          onClick={() => {
            isFullOpened ? closeFullNavbar() : openFullNavbar()
          }}
        >
          {isFullOpened ? <ChevronLeft /> : <ChevronRight />}
        </button>

        <div
          className={`
            flex items-center justify-between border-gray-100 border-t-[2px]
            pt-[10px] ${isFullOpened ? "lg:flex" : "lg:flex-col lg:pt-[0px] lg:gap-[4px]"}
          `}
        >
          <Link
            to="/admin/profile"
            onClick={closeNavbar}
            className={`
              ${!isFullOpened && "lg:p-[8px]"}
              flex items-center gap-[8px] text-gray-700 py-[10px] px-[15px] 
              hover:bg-gray-100 rounded-[30px] 
            `}
          >
            <CircleUserRound size={22}/>
            <span className={`${isFullOpened ? "lg:block" : "lg:hidden"} text-[18px]`}>Profile</span>
          </Link>
          <Button
            onClick={() => {
              logout();
              closeNavbar();
            }}
          >
            <LogOut size={18}/>
            <span className={`${isFullOpened ? "lg:block" : "lg:hidden"} ml-[5px]`}>Sign out</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
