import React, { useContext, useEffect, useState } from "react";

import { Answer as AnswerType, SelectItem } from "../../types";

import { useMutation, useQuery } from "@tanstack/react-query";
import { createQuestion, fetchingBibleBooks } from "../../services";

import QuestionsContext from "../../context/questions-context";

import { Select, Input, CardBox, Button } from "../../components";

import { AlertTriangle, Check, Loader } from "lucide-react";

import { v4 as uuidv4 } from "uuid";

import toast from "react-hot-toast";

type QuestionFormTypes = {
  selectedBook: SelectItem;
  chapter: string;
  verses: {
    startingVerse: string;
    endingVerse: string;
  };
  priority: string;
  questionText: string;
  answers: { id: string; text: string; isRight: boolean }[];
};

export const AddingQuestionForm = () => {
  const { data: bibleBooks, isSuccess: isBibleBooksSuccess } = useQuery({
    queryFn: fetchingBibleBooks,
    queryKey: ["bibleBooks"],
  });

  const { getVerses, currentVerses } = useContext(QuestionsContext);

  const [chaptersCount, setChaptersCount] = useState(0);
  const [formData, setFormData] = useState<QuestionFormTypes>({
    selectedBook: {
      id: bibleBooks ? bibleBooks[0].id : "",
      name: bibleBooks ? bibleBooks[0].name : "Empty",
    },
    chapter: "1",
    verses: {
      startingVerse: "1",
      endingVerse: "1",
    },
    priority: "1",
    questionText: "",
    answers: [
      {
        id: uuidv4(),
        text: "",
        isRight: true,
      },
      {
        id: uuidv4(),
        text: "",
        isRight: false,
      },
      {
        id: uuidv4(),
        text: "",
        isRight: false,
      },
      {
        id: uuidv4(),
        text: "",
        isRight: false,
      },
    ],
  });

  const createQuestionMutation = useMutation({
    mutationFn: (newQuestion: {
      bookId: string;
      bookChapter: number;
      difficulty: string;
      questionText: string;
      answers: AnswerType[];
      verseIds: string[];
      priority: number;
    }) => createQuestion(newQuestion),
    onSuccess: () => {
      toast.success("Successfully added");

      setFormData(prevFormData => {
        const updatedAnswers = prevFormData.answers.map((answer, index) => {
          return index !== 0 ?
            { ...answer, text: "", isRight: false } :
            { ...answer, text: "", isRight: true };
        });

        return {
          ...prevFormData,
          questionText: "",
          priority: "1",
          verses: {
            ...formData.verses,
            endingVerse: formData.verses.startingVerse,
          },
          answers: updatedAnswers,
        };
      });
    },
    onError: () => {
      toast.error("Something went wrong");
    }
  });

  const difficulty = [
    { id: "id1", name: "level-1" },
    { id: "id2", name: "level-2" },
    { id: "id3", name: "level-3" },
  ];
  const [selectedDiff, setSelectedDiff] = useState(difficulty[0]);

  const bibleBooksChangeHandler = (value: { id: string; name: string }) => {
    if (isBibleBooksSuccess) {
      for (let book of bibleBooks || []) {
        if (value.id === book.id) {
          setChaptersCount(book.chaptersCount);
          setFormData({
            ...formData,
            selectedBook: {
              id: value.id,
              name: value.name,
              bookNumber: book.bookNumber,
              chaptersCount: book.chaptersCount,
              sortingOrder: book.sortingOrder,
            },
            verses: {
              startingVerse: "1",
              endingVerse: "1",
            },
            chapter: "1",
          });
        }
      }
    }
  };

  const chapterHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (
      inputValue === "" ||
      (parseInt(inputValue) <= Number(chaptersCount) &&
        parseInt(inputValue) >= 1)
    ) {
      setFormData({
        ...formData,
        verses: {
          startingVerse: "1",
          endingVerse: "1",
        },
        chapter: inputValue,
      });
    }
  };

  const answerTextChangeHandler = (id: string, newText: string) => {
    setFormData(prevFormData => {
      const updatedAnswers = prevFormData.answers.map(answer => {
        if (answer.id === id) {
          return { ...answer, text: newText };
        }

        return answer;
      });

      return { ...prevFormData, answers: updatedAnswers };
    });
  };

  const answerCheckChangeHandler = (id: string) => {
    setFormData(prevFormData => {
      const updatedAnswers = prevFormData.answers.map(answer => ({
        ...answer,
        isRight: answer.id === id,
      }));

      return { ...prevFormData, answers: updatedAnswers };
    });
  };

  const priorityHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      priority: event.target.value,
    });
  };

  const startingVerseChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

      if (Number(formData.verses.startingVerse) >= Number(formData.verses.endingVerse)) {
        setFormData({
          ...formData,
          verses: {
            startingVerse: inputValue,
            endingVerse: inputValue,
          }
        });
      } else {
        setFormData({
          ...formData,
          verses: {
            ...formData.verses,
            startingVerse: inputValue,
          }
        });
      }
  };

  const endingVerseChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setFormData({
      ...formData,
      verses: {
        ...formData.verses,
        endingVerse: inputValue,
      }
    });
  };

  const submitQuestionFormHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const checkAnswers = () => {
      for (const answer of formData.answers) {
        if (answer.text === "") return false;
      }

      return true;
    };

    if (formData.questionText === "" || !checkAnswers()) {
      toast(t => (
        <div className="flex items-center gap-[8px]">
          <AlertTriangle
            size={18}
            strokeWidth={2.2}
            className="text-orange-300"
          />
          <div>Some fields are empty</div>
        </div>
      ));

      return;
    }

    if (formData.selectedBook.name !== "") {
      const vIds = [];

      for (let i = 0; i < currentVerses.length; i++) {
        if (
          i >= (Number(formData.verses.startingVerse) - 1) &&
          i <= (Number(formData.verses.endingVerse) - 1) &&
          Number(formData.verses.endingVerse) >= Number(formData.verses.startingVerse)
        ) {
          vIds.push(currentVerses[i]._id);
        }
      }

      const questionAnswers = formData.answers.map(answer => ({
        text: answer.text.toUpperCase(),
        isRight: answer.isRight,
      }));

      createQuestionMutation.mutate({
        bookId: formData.selectedBook.id,
        bookChapter: Number(formData.chapter),
        difficulty: selectedDiff.name,
        questionText: formData.questionText.trim(),
        answers: questionAnswers,
        verseIds: vIds,
        priority: Number(formData.priority),
      });
    }
  };

  useEffect(() => {
    if (bibleBooks) {
      setFormData({
        ...formData,
        selectedBook: bibleBooks[0],
      });
      setChaptersCount(bibleBooks[0].chaptersCount);
    }
  }, [bibleBooks]);

  const getVerseCount = () => {
    if (formData.selectedBook.name !== "") {
      if (formData.selectedBook.bookNumber) {
        getVerses(formData.selectedBook.bookNumber, Number(formData.chapter));
      }
    }
  };

  useEffect(() => {
    getVerseCount();
  }, [formData.selectedBook, formData.chapter]);

  return (
    <>
      <form
        className="mx-auto w-full md:w-[650px]"
        onSubmit={submitQuestionFormHandler}
      >
        <CardBox>
          <div className="gap-[8px] flex flex-wrap sm:grid sm:grid-cols-2">
            <div className="w-full">
              <label className="font-semibold block mb-2">Book</label>
              <Select
                listData={bibleBooks || []}
                selectedValue={formData.selectedBook}
                onChangeSelect={(value) => bibleBooksChangeHandler(value)}
                className="mt-[8px]"
              />
            </div>
            <div className="w-full">
              <label className="font-semibold">Chapter</label>
              <Input
                type="number"
                min="1"
                max={chaptersCount}
                value={formData.chapter}
                onChange={chapterHandler}
                className="w-full mt-[8px] border-transparent border-[2px]"
              />
            </div>
            <div className="w-full">
              <label className="font-semibold">Starting verse</label>
              <Input
                type="number"
                min="1"
                max={currentVerses.length}
                value={formData.verses.startingVerse}
                onChange={startingVerseChangeHandler}
                className="w-full mt-[8px] border-transparent border-[2px]"
              />
            </div>
            <div className="w-full">
              <label className="font-semibold">Ending verse</label>
              <Input
                type="number"
                min={formData.verses.startingVerse}
                max={currentVerses.length}
                value={formData.verses.endingVerse}
                onChange={endingVerseChangeHandler}
                className="w-full mt-[8px] border-transparent border-[2px]"
              />
            </div>
            <div className="w-full">
              <label className="font-semibold">Priority</label>
              <Input
                type="number"
                min="1"
                value={formData.priority}
                onChange={priorityHandler}
                className="w-full mt-[8px] border-transparent border-[2px]"
              />
            </div>
            <div className="w-full">
              <label className="font-semibold block mb-2">Difficulty</label>
              <Select
                listData={difficulty}
                selectedValue={selectedDiff}
                onChangeSelect={(value: { id: string; name: string }) => setSelectedDiff(value)}
              />
            </div>
          </div>
          <div className="flex justify-center mt-[8px]">
            <div className="w-full">
              <label className="font-semibold">Question</label>
              <textarea
                value={formData.questionText}
                onChange={event => {
                  setFormData({
                    ...formData,
                    questionText: event.target.value,
                  })
                }}
                className="
                  block px-[15px] py-[10px] mt-[8px] rounded-[10px] bg-gray-100 border-transparent border-[2px]
                  focus:transition-all focus:ease-in-out placeholder:text-gray-400 focus:border-[2px] focus:border-blue-400
                  w-full max-w-full min-h-[130px] max-h-[400px]
                "
              />
            </div>
          </div>
        </CardBox>

        <ul className="mt-[8px] flex flex-wrap gap-[8px] sm:grid sm:grid-cols-2 sm:gap-[8px]">
          {formData.answers.map((answer, index)=> (
            <li key={answer.id} className="block w-full">
              <CardBox className="w-full">
                <div className="flex items-center gap-[8px] mb-4">
                  <div
                    className={`
                      ${answer.isRight && "bg-blue-400 text-white border-white"}
                      border-[1px] border-gray-200 p-[2px] text-white cursor-pointer
                      rounded-[5px] w-[19px] h-[19px] flex items-center justify-center
                      focus:border-[2px] focus:border-red-500
                    `}
                    onClick={() => answerCheckChangeHandler(answer.id)}
                  >
                    <Check size={22} strokeWidth={2.2} />
                  </div>
                  <input
                    id={`answer-check-${index}`}
                    type="radio"
                    name="is-right"
                    className="hidden"
                    checked={answer.isRight}
                    onChange={() => answerCheckChangeHandler(answer.id)}
                  />
                  <label
                    htmlFor={`answer-check-${index}`}
                    className="font-semibold"
                  >
                    Right answer
                  </label>
                </div>

                <textarea
                  className="
                    block px-[15px] py-[10px] mt-[8px] rounded-[10px] bg-gray-100 border-transparent border-[2px]
                    w-full max-w-full min-h-[130px] uppercase focus:transition-all focus:ease-in-out
                    placeholder:text-gray-400 focus:border-[2px] focus:border-blue-400
                  "
                  placeholder="Enter answer"
                  value={answer.text}
                  onChange={event => {
                    answerTextChangeHandler(answer.id, event.target.value);
                  }}
                />
              </CardBox>
            </li>
          ))}
        </ul>

        <div className="flex justify-end">
          <Button
            disabled={createQuestionMutation.isPending}
            className="mt-[10px] w-[140px]"
          >
            {createQuestionMutation.isPending ? (
              <Loader className="animate-spin my-[3px]" size={18}/>
            ) : (
              <span>Add question</span>
            )}
          </Button>
        </div>
      </form>
    </>
  );
};