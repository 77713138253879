import { NavbarLink } from "./NavbarLink";
import { Users, FileQuestion, LayoutPanelTopIcon, Contact, UserPlus } from "lucide-react";

const NAV_LINK_ITEMS = [
  {
    title: "Dashboard",
    path: "/admin/dashboard",
    icon: <LayoutPanelTopIcon size={19} strokeWidth={2.2} />,
  },
  {
    title: "Questions",
    path: "/admin/questions",
    icon: <FileQuestion size={19} strokeWidth={2.2} />,
  },
  {
    title: "Users",
    path: "/admin/users/1",
    icon: <Users size={19} strokeWidth={2.2} />,
  },
  {
    title: "Users feedback",
    path: "/admin/users-feedback",
    icon: <Contact size={19} strokeWidth={2.2} />,
  },
  {
    title: "Rooms",
    path: "/admin/rooms",
    icon: <UserPlus size={19} strokeWidth={2.2} />,
  },
];

export const NavbarLinkList = ({
  closeNavbar,
}: {
  closeNavbar?: () => void;
}) => {
  return (
    <ul
      className="
        border-gray-100 border-t-[2px] mt-[10px] py-[10px]
        flex flex-col h-[calc(100%-75px)]
      "
    >
      {NAV_LINK_ITEMS.map((item) => (
        <NavbarLink
          key={Math.random()}
          title={item.title}
          path={item.path}
          icon={item.icon}
          closeNavbar={closeNavbar}
        />
      ))}
    </ul>
  );
};
