import React from "react";

import { Trash2 } from "lucide-react";

import { format } from "date-fns";

type UsersFeedbackRowProps = {
  id: string;
  name: string;
  email: string;
  createdDate: string;
  message: string;
  openDeletingModal?: () => void;
};

export const UsersFeedbackRow: React.FC<UsersFeedbackRowProps> = ({
  id,
  name,
  email,
  createdDate,
  message,
  openDeletingModal,
}) => {
  const date = format(new Date(createdDate), "dd MMM, yyyy");

  return (
    <>
      <tr className="transition duration-[0.3s] hover:bg-gray-100">
        <td className="my-[10px] block font-semibold">{name.length === 0 ? <span>No name</span> : <span>{name}</span>}</td>
        <td>
          <div
            className="
              p-[4px] bg-gray-300 text-sm text-gray-500 text-center
              rounded-[8px] inline
            "
          >
            {date}
          </div>
        </td>
        <td className="block font-semibold">
          <div className="w-[200px]">{email}</div>
        </td>
        <td className="font-semibold py-[10px]">
          <div className="max-w-[300px]">{message}</div>
        </td>
        <td className="text-center">
          <button
            onClick={openDeletingModal}
            className="
              text-gray-700 text-center p-[8px] transition-all ease-in
              rounded-[5px] hover:bg-gray-200 hover:text-gray-900
            "
          >
            <Trash2 size={16}/>
          </button>
        </td>
      </tr>
    </>
  );
};