import React, { useState } from "react";

import { addRoom, changeRoom } from "../../services";
import { useMutation } from "@tanstack/react-query";

import {
  Input,
  Button,
  Loader
} from "../../components";

import toast from "react-hot-toast";

type RoomFormProps = {
  id?: string;
  name: string;
  description: string;
  onCloseModal?: () => void;
  refetchRooms?: () => void;
};

export const RoomForm: React.FC<RoomFormProps> = ({
  id,
  name,
  description,
  onCloseModal,
  refetchRooms,
}) => {
  const [formData, setFormData] = useState({
    name: name,
    description: description,
  });

  const { mutate: addNewRoomMutation, isPending: isAddingPending} = useMutation({
    mutationFn: (room: { name: string; description: string }) => addRoom(room.name, room.description),
    onSuccess: () => {

    },
  });

  const { mutate: changeRoomMutation, isPending: isChangingPending} = useMutation({
    mutationFn: (room: { id: string; name: string; description: string }) => changeRoom(id ?? "", room.name, room.description),
    onSuccess: () => {
      if (onCloseModal) {
        onCloseModal();
      }

      refetchRooms && refetchRooms();
      toast.success("Successfully changed room");
    },
    onError: () => {
      toast.error("Something went wrong");
    }
  });

  const submitRoomFormHandler = (event: React.FormEvent) => {
    event.preventDefault();

    id ?
      changeRoomMutation({ id: id, name: formData.name, description: formData.description }):
      addNewRoomMutation({ name: formData.name, description: formData.description });
  };

  return (
    <form onSubmit={submitRoomFormHandler} className="flex flex-col gap-[10px]">
      {!id && <p className="font-semibold text-[18px]">Add new room</p>}

      <Input
        className="w-full"
        placeholder="Room name"
        value={formData.name}
        onChange={(event) => setFormData({ ...formData, name: event.target.value })}
      />
      <textarea
        value={formData.description}
        onChange={event => {
          setFormData({
            ...formData,
            description: event.target.value,
          });
        }}
        placeholder="Room description"
        className="
          block px-[15px] py-[10px] rounded-[10px] bg-gray-100 border-transparent
          border-[2px] w-full max-w-full min-h-[90px] max-h-[400px] focus:transition-all
          focus:ease-in-out placeholder:text-gray-400 focus:border-[2px] focus:border-blue-400
        "
      />

      <Button
        disabled={isAddingPending || isChangingPending}
        className="h-[44px]"
      >
        {isAddingPending || isChangingPending ? (
          <Loader />
        ) : (
          <>
            {id ? <span>Change room</span> : <span>Add room</span>}
          </>
        )}
      </Button>
    </form>
  );
};