import React, { useContext, useEffect, useState } from "react";

import { Question, SelectItem } from "../../types";

import { useGetBibleBooks, useGetFilteredQuestions } from "../../hooks";

import { Link } from "react-router-dom";

import QuestionsContext from "../../context/questions-context";

import {
  CardBox,
  Button,
  MainModal,
  ChangingQuestionForm,
  Select,
  MuiDataTable,
  Toggle,
} from "../../components";

import { Trash2, Plus, X, Check, Loader } from "lucide-react";

import { GridColDef } from "@mui/x-data-grid";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteQuestions,
  fetchingSortedQuestions,
  changeQuestion,
} from "../../services";

type Filters = {
  generatedWithAi: boolean;
};

export const QuestionsPage = () => {
  const { findBibleBookName, totalQuestionsCount } = useContext(QuestionsContext);

  const client = useQueryClient();
  const { bibleBooks } = useGetBibleBooks();

  const [questionsList, setQuestionsList] = useState<Question[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null,
  );
  const [openChangingModal, setOpenChangingModal] = useState(false);
  const [openDeletingModal, setOpenDeletingModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState<SelectItem>({
    id: "1",
    name: "All books",
  });
  const [selectedBookChapter, setSelectedBookChapter] = useState<SelectItem>({
    id: "1",
    name: "All books",
  });
  const [bookChapters, setBookChapters] = useState<SelectItem[]>([]);
  const [paginationValues, setPaginationValues] = useState<{
    pageSize: number;
    page: number;
  }>({ pageSize: 100, page: 0 });
  const [filters, setFilters] = useState<Filters>({ generatedWithAi: false });

  const {
    questions,
    isQuestionsPending,
    isQuestionsSuccess,
    refetchQuestions,
  } = useGetFilteredQuestions(paginationValues.page * paginationValues.pageSize, paginationValues.pageSize, filters);

  const { mutate: deleteNeededQuestions, isPending: deletingPending } = useMutation({
    mutationFn: deleteQuestions,
    onSuccess: () => {
      const arr = [];

      for (let item of questionsList) {
        let counter = 0;

        for (let id of selectedIds) {
          if (item.id === id) counter++;
        }

        if (counter === 0) arr.push(item);
      }

      setQuestionsList(arr);

      client.invalidateQueries({
        queryKey: ["questions"],
        refetchType: "none",
      });

      setOpenDeletingModal(false);
    },
  });

  const {
    data: filteredQuestions,
    refetch: refetchSortedQuestions,
    isSuccess: isFilteredQuestionsSuccess,
  } = useQuery({
    queryFn: () =>
      fetchingSortedQuestions(selectedBook.id, selectedBookChapter.name, filters),
    queryKey: ["questions"],
    staleTime: 5_000_000,
  });

  const { mutate: changeCurrentQuestion, isPending: isChangeQuestionPending } = useMutation({
    mutationFn: (changedQuestion: {
      question: Question;
      bookId: string;
      bookName: string;
      bookChapter: string;
    }) => changeQuestion(changedQuestion),
    onSuccess: () => {
      if (selectedBook.name === "All books") {
        refetchQuestions();
      } else {
        refetchSortedQuestions();
      }

      setOpenChangingModal(false);
    },
  });

  const columns: GridColDef[] = [
    {
      field: "questionText",
      headerName: "Question",
      width: 400,
      headerClassName: "text-gray-400 font-normal text-[16px]",
      cellClassName: "font-semibold",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (value) => (
        <span className="py-[10px]">{value.row.questionText}</span>
      ),
    },
    {
      field: "book",
      headerName: "Book",
      width: 210,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "text-gray-400 font-normal text-[16px]",
      renderCell: (value) => (
        <span>{findBibleBookName(value.row.book, bibleBooks)}</span>
      ),
    },
    {
      field: "chapter",
      headerName: "Chapter",
      width: 110,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: "text-gray-400 font-normal text-[16px]",
      renderCell: (value) => (
        <span className="font-semibold mx-auto">{value.row.chapter}</span>
      ),
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 110,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: "text-gray-400 font-normal text-[16px]",
      renderCell: (value) => (
        <span className="font-semibold mx-auto">{value.row.priority}</span>
      ),
    },
    // {
    //   field: "verse",
    //   headerName: "Verse",
    //   width: 110,
    //   disableColumnMenu: true,
    //   sortable: false,
    //   headerClassName: "text-gray-400 font-normal text-[16px]",
    //   renderCell: (value) => <span className="font-semibold mx-auto">{value.row.chapter}</span>
    // },
    {
      field: "answers",
      headerName: "Answers",
      sortable: false,
      disableColumnMenu: true,
      width: 450,
      headerClassName: "text-gray-400 font-normal text-[16px]",
      renderCell: value => {
        return (
          <ul className="py-[8px]">
            {value.row.answers.map(
              (
                answer: {
                  isRight: boolean;
                  text:
                    | string
                    | React.ReactElement<
                        any,
                        string | React.JSXElementConstructor<any>
                      >
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                },
                index: number,
              ) => (
                <li
                  key={Math.random()}
                  className="flex items-center gap-[5px] leading-6"
                >
                  <span>
                    {index + 1}. {answer.text}
                  </span>
                  <div className="text-blue-400">
                    {answer.isRight && <Check size={16} />}
                  </div>
                </li>
              ),
            )}
          </ul>
        );
      },
    },
  ];

  const deleteQuestionsHandler = () => {
    deleteNeededQuestions(selectedIds);
  };

  const openQuestion = (questionValue: any) => {
    setSelectedQuestion(questionValue.row);
    setOpenChangingModal(true);
  };

  const bibleBooksChangeHandler = (value: SelectItem) => {
    setSelectedBook(value);
    setSelectedBookChapter({ id: "id1", name: "1" });

    const chapters = Array.from(
      { length: value.chaptersCount ?? 1 },
      (_, index) => ({ id: `id-${index}`, name: (index + 1).toString() }),
    );
    setBookChapters(chapters);
  };

  const onToggleFilterGeneratedWithAi = (generatedWithAi = false) => {
    const updatedFilters = {
      ...filters,
      generatedWithAi,
    };

    setFilters(updatedFilters);
  };

  const [questionsCount, setQuestionsCount] = useState(totalQuestionsCount);

  useEffect(() => {
    if (selectedBook.name === "All books") {
      refetchQuestions();
    }
  }, [paginationValues, selectedBook, filters]);

  useEffect(() => {
    if (selectedBook.name === "All books") {
      setQuestionsList(questions || []);
      setQuestionsCount(totalQuestionsCount);
    }
  }, [questions, selectedBook]);

  useEffect(() => {
    if (selectedBook.name !== "All books") {
      refetchSortedQuestions();
    }
  }, [selectedBook, selectedBookChapter, filters]);

  useEffect(() => {
    if (selectedBook.name !== "All books") {
      setQuestionsList(filteredQuestions || []);
      setQuestionsCount(filteredQuestions?.length ?? 0);
    }
  }, [refetchSortedQuestions, filteredQuestions]);

  return (
    <div>
      <CardBox
        className={`
          flex items-center relative justify-between pb-[140px] sm:pb-[80px] md:pb-[20px]
          ${selectedBook.name === "All books" && "pb-[80px]"}
        `}
      >
        <div className="flex items-center">
          <h1 className="font-semibold text-[25px]">Questions</h1>

          <div
            className="
              absolute flex-col bottom-[20px] flex gap-[10px] items-center
              w-[calc(100%-40px)] sm:flex-row md:static md:ml-[10px]
            "
          >
            <div className="w-full md:w-[200px]">
              <Select
                onChangeSelect={(value) => {
                  bibleBooksChangeHandler(value);
                }}
                selectedValue={selectedBook}
                listData={[
                  {id: "1", name: "All books"},
                  ...(bibleBooks || []),
                ]}
              />
            </div>
            {selectedBook.name !== "All books" && (
              <div className="w-full md:w-[200px]">
                <Select
                  onChangeSelect={(value) => setSelectedBookChapter(value)}
                  selectedValue={selectedBookChapter}
                  listData={bookChapters}
                />
              </div>
            )}
          </div>

          <div className="ml-4 w-full hidden gap-2 items-center xl:flex">
            <p className="font-regular text-[16px]">Generated by AI</p>
            <Toggle
              enabled={filters.generatedWithAi}
              onToggle={onToggleFilterGeneratedWithAi}
            />
          </div>
        </div>
        <div className="flex items-center gap-[5px]">
          <Button
            disabled={selectedIds.length === 0}
            onClick={() => setOpenDeletingModal(true)}
            className="p-[10px] bg-red-400 hover:bg-red-300 disabled:bg-gray-300"
          >
            <Trash2 strokeWidth={2.2} size={18} />
          </Button>
          <Link
            to="/admin/questions/add-question"
            className="
              bg-gray-900 text-white font-semibold text-md flex items-center gap-[5px]
              py-[7px] px-[11px] rounded-[8px] hover:bg-gray-800
            "
          >
            <Plus strokeWidth={2.2} size={18} />
            <span>Add new</span>
          </Link>
        </div>
      </CardBox>
      <CardBox className="mt-[8px] relative z-[1] h-[calc(100dvh-164.4px)] lg:h-[calc(100vh-116px)]">
        <div className="flex justify-between items-start">
          <p className="font-semibold mb-[10px] text-[18px]">
            Total questions - {" "}
            {isQuestionsPending ? (
              <span className="text-sm font-thin text-gray-400">counting...</span>
            ) : (
              <span>{questionsCount}</span>
            )}
          </p>
          <div className="ml-4 gap-2 xl:hidden flex items-center">
            <p className="font-regular text-[16px]">
              <span className="sm:block hidden">Generated by AI</span>
              <span className="block sm:hidden">By AI</span>
            </p>
            <Toggle
              enabled={filters.generatedWithAi}
              onToggle={onToggleFilterGeneratedWithAi}
            />
          </div>
        </div>
        <MuiDataTable
          rows={questionsList}
          columns={columns}
          selectRowsId={(ids) => setSelectedIds(ids)}
          loading={isQuestionsPending}
          openQuestion={(questionValue) => openQuestion(questionValue)}
          onGetPaginationValues={(value) => setPaginationValues(value)}
          rowsCount={questionsCount}
          isFiltered={filters.generatedWithAi}
        />
      </CardBox>

      <MainModal
        title="Deleting questions"
        description="Are you sure? This action cannot be undone."
        isOpen={openDeletingModal}
        onClose={() => setOpenDeletingModal(false)}
        className="w-[400px] relative mt-[-50px] deleting-modal"
      >
        <div
          className="
            absolute py-[10px] px-[20px] left-0 right-0
            w-full bg-gray-100 min-h-[50px] rounded-b-[15px]
          "
        >
          <div className="flex justify-end items-center gap-[8px]">
            <Button
              onClick={() => setOpenDeletingModal(false)}
              className="bg-white py-[8px] text-gray-700 hover:bg-white"
            >
              Cancel
            </Button>
            <Button
              onClick={deleteQuestionsHandler}
              disabled={deletingPending}
              className="bg-red-400 py-[8px] w-[96px] disabled:bg-red-200 hover:bg-red-300"
            >
              {deletingPending ? (
                <Loader className="animate-spin my-[3px]" size={18} />
              ) : (
                <span>Delete</span>
              )}
            </Button>
          </div>
        </div>
      </MainModal>

      <MainModal
        title="Change question"
        className="w-[650px] max-h-[100dvh] relative rounded-none overflow-y-auto sm:rounded-[15px] sm:max-h-[calc(100vh-20px)]"
        isOpen={openChangingModal}
        onClose={() => setOpenChangingModal(false)}
      >
        <button
          className="absolute block top-0 right-0 p-[10px] md:hidden"
          onClick={() => setOpenChangingModal(false)}
        >
          <X size={18} />
        </button>
        <ChangingQuestionForm
          questionId={selectedQuestion?.id ?? ""}
          book={selectedQuestion?.book ?? "loading..."}
          // bookNumber={}
          questionText={selectedQuestion?.questionText ?? "loading..."}
          chapter={selectedQuestion?.chapter ?? 1}
          difficulty="level-1"
          answers={selectedQuestion?.answers ?? []}
          onCloseModal={() => setOpenChangingModal(false)}
          currentBook={{
            bookId: selectedBook.id,
            bookChapter: selectedBookChapter.name,
            bookName: selectedBook.name,
          }}
          onChangeCurrentQuestion={(value) => changeCurrentQuestion(value)}
          verse={selectedQuestion?.verse ?? [""]}
          priority={selectedQuestion?.priority || 1}
          submitLoading={isChangeQuestionPending}
        />
      </MainModal>
    </div>
  );
};
