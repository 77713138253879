import React from "react";

import { AuthForm } from "../../components";

type AuthPageProps = {
  signIn: boolean;
  forgotPassword: boolean;
  resetPassword: boolean;
};

export const AuthPage: React.FC<AuthPageProps> = ({
  signIn,
  resetPassword,
  forgotPassword,
}) => {
  return (
    <div className="flex items-center justify-center h-[100dvh]">
      <div>
        <h1 className="text-center text-[27px] font-semibold text-gray-700">
          Bible gym <span className="text-blue-400">Admin console</span>
        </h1>
        <div className="sm:bg-white w-[380px] rounded-[20px] mt-[50px] px-[32px] py-[35px] sm:shadow-lg">
          <h2 className="text-[25px] font-semibold">
            {signIn && <span>Sign In</span>}
            {forgotPassword && <span>Request new password</span>}
            {resetPassword && <span>New password</span>}
          </h2>
          <AuthForm
            signIn={signIn}
            forgotPassword={forgotPassword}
            resetPassword={resetPassword}
          />
        </div>
      </div>
    </div>
  );
};
