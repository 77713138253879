import React from "react";

import { Trash2 } from "lucide-react";

import { format } from "date-fns";

type UserRowProps = {
  id: string;
  name: string;
  email: string;
  createdDate: string;
  openDeletingModal?: () => void;
};

export const UserRow: React.FC<UserRowProps> = ({
  id,
  name,
  email,
  createdDate,
  openDeletingModal,
}) => {
  const date = format(new Date(createdDate), "dd MMM, yyyy");

  return (
    <>
      <tr className="transition duration-[0.3s] hover:bg-gray-100">
        <td className="my-[10px] block font-semibold">{name}</td>
        <td>
          <div
            className="
            p-[4px] bg-gray-300 text-sm text-gray-500 text-center
            rounded-[8px] inline
          "
          >
            {date}
          </div>
        </td>
        <td className="block font-semibold">{email}</td>
        <td className="text-center">
          <button
            onClick={openDeletingModal}
            className="
              text-gray-700 text-center p-[8px] transition-all ease-in
              rounded-[5px] hover:bg-gray-200 hover:text-gray-900
            "
          >
            <Trash2 size={16} />
          </button>
        </td>
      </tr>
    </>
  );
};
