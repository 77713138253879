import { Route, Routes } from "react-router-dom";

import { UsersPage } from "../pages";

export const UsersPageRouter = () => {
  return (
    <Routes>
      <Route path=":page" element={<UsersPage />} />
    </Routes>
  );
};
