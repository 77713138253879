import React, { useContext } from "react";

import { NavLink } from "react-router-dom";
import FullOpenNavbarContext from "../../context/full-open-navbar";

type NavbarLinkProps = {
  title: string;
  path: string;
  icon?: React.ReactNode;
  closeNavbar?: () => void;
};

export const NavbarLink: React.FC<NavbarLinkProps> = ({
  title,
  path,
  icon,
  closeNavbar,
}) => {
  const { isFullOpened } = useContext(FullOpenNavbarContext);

  return (
    <li onClick={closeNavbar} className="relative group">
      <NavLink
        to={path}
        className={({isActive}) => {
          return `
            ${isActive && "bg-gray-900 text-white font-semibold"}
            ${!isActive && "hover:bg-gray-100"} 
            ${isFullOpened ? "lg:justify-start" : "lg:px-[0px] lg:h-[48px] lg:justify-center lg:py-[10px] lg:rounded-[10px]"}
            flex items-center gap-[8px] px-[15px] py-[12px] rounded-[30px]
            transition-all ease-in-out text-gray-700
          `;
        }}
      >
        {icon}
        <span className={`${isFullOpened ? "block" : "lg:hidden"} block`}>
          {title}
        </span>
      </NavLink>

      {!isFullOpened && (
        <span
          className="
            absolute start-full hidden top-1/2 ms-4 -translate-y-1/2
            p-[5px] rounded-[4px] bg-gray-800 text-white text-[14px]
            font-semibold lg:group-hover:block
          "
        >
          {title}
        </span>
      )}
    </li>
  );
};
