import { useQuery } from "@tanstack/react-query";

import { RoomsApiService } from "../../services/rooms";

export const useGetRooms = (offset: number, limit: number, name: string) => {
  const { ...rest } = useQuery({
    queryFn: () => RoomsApiService.getRooms(offset, limit, name),
    queryKey: ["rooms"],
    staleTime: 5_000_000,
  });

  return {
    ...rest,
  };
};