import React, { useContext, useState } from "react";

import { User } from "../../types";

import AuthContext from "../../context/auth-context";

import { UserRow } from "./UserRow";
import { MainModal, Button } from "../../components";
import { Loader } from "lucide-react";

export const UsersTable = ({ users }: { users: User[] }) => {
  const { usersLoading } = useContext(AuthContext);
  const tableHead = ["Name", "Created date", "Email", ""];
  const [modalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState("");

  const deleteUserHandler = () => {};

  return (
    <div className="h-[90%] overflow-y-auto overflow-x-visible">
      <div className="overflow-x-visible w-[700px] md:w-full">
        <table className="w-full table-fixed mt-[30px]">
          <thead className="p-[20px] border-gray-200 border-y-[1px]">
            <tr className="text-left">
              {tableHead.map((item) => (
                <th
                  key={Math.random()}
                  className="first:block font-semibold first:py-[10px] text-gray-400"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          {users.length !== 0 && (
            <tbody>
              {users.map((user) => (
                <UserRow
                  key={user.id}
                  id={user.id}
                  name={user.name}
                  createdDate={user.createdDate}
                  email={user.email}
                  openDeletingModal={() => {
                    setModalOpen(true);
                    setUserId(user.id);
                  }}
                />
              ))}
            </tbody>
          )}
        </table>
      </div>

      {users.length === 0 && !usersLoading && (
        <p className="text-center text-gray-300 mt-[100px]">No users yet</p>
      )}
      {users.length === 0 && usersLoading && (
        <div className="h-1/2 flex items-center justify-center text-gray-400">
          <Loader size={20} className="animate-spin" />
        </div>
      )}

      <MainModal
        title="Deleting user"
        description="Are you sure? This action cannot be undone."
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        className="w-[400px] relative mt-[-50px] deleting-modal"
      >
        <div className="absolute py-[10px] px-[20px] left-0 right-0 w-full bg-gray-100 min-h-[50px] rounded-b-[15px]">
          <div className="flex justify-end items-center gap-[8px]">
            <Button
              onClick={() => setModalOpen(false)}
              className="bg-white py-[8px] text-gray-700 hover:bg-white"
            >
              Cancel
            </Button>
            <Button
              onClick={deleteUserHandler}
              className="bg-red-400 py-[8px] hover:bg-red-300"
            >
              Delete
            </Button>
          </div>
        </div>
      </MainModal>
    </div>
  );
};
