import React, { useContext, useState } from "react";

import { Link } from "react-router-dom";

import AuthContext from "../../context/auth-context";

import { Input, Button } from "../../components";

import { Loader } from "lucide-react";

type AuthFormProps = {
  signIn: boolean;
  forgotPassword: boolean;
  resetPassword: boolean;
};

export const AuthForm: React.FC<AuthFormProps> = ({
  signIn,
  forgotPassword,
  resetPassword,
}) => {
  const { auth, loading } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (email.length === 0 || email.length === 0) return;

    auth(signIn, forgotPassword, resetPassword, {
      email: email,
      password: password,
      code: verificationCode,
      newPassword: newPassword,
    });
  };

  return (
    <form
      onSubmit={submitHandler}
      className="flex flex-col gap-[7px] mt-[20px]"
    >
      <Input
        type="email"
        disabled={loading}
        placeholder="Email"
        value={email}
        onChange={event => setEmail(event.target.value)}
      />
      {signIn && (
        <Input
          type="password"
          disabled={loading}
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      )}
      {resetPassword && (
        <>
          <Input
            type="password"
            disabled={loading}
            placeholder="New password"
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <Input
            type="text"
            disabled={loading}
            placeholder="Verification code"
            maxLength={6}
            value={verificationCode}
            onChange={(event) => setVerificationCode(event.target.value)}
          />
        </>
      )}
      <Button disabled={loading} className="mt-[10px]">
        {loading ? (
          <Loader className="animate-spin my-[3px]" size={18} />
        ) : (
          <div>
            {signIn && <span>Sign In</span>}
            {forgotPassword && <span>Send password</span>}
            {resetPassword && <span>Save</span>}
          </div>
        )}
      </Button>
      <Link
        to={signIn && !loading ? "/forgot-password" : "/sign-in"}
        className={`
          ${loading && "cursor-default text-blue-200"}
          text-blue-400 mt-[10px] text-sm font-[500]
        `}
      >
        {signIn ? <span>Forgot your password?</span> : <span>Sign in</span>}
      </Link>
    </form>
  );
};
