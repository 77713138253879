import React, { useContext } from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import AuthContext from "../context/auth-context";

import { AuthPage, MainPage } from "../pages";

export const Router = () => {
  const { isLoggedIn, isPasswordReset } = useContext(AuthContext);

  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route path="/admin/*" element={<MainPage />} />
          <Route
            path="/*"
            element={<Navigate to="/admin/dashboard" replace />}
          />
        </>
      ) : (
        <>
          <Route
            path="/sign-in"
            element={
              <AuthPage
                signIn={true}
                forgotPassword={false}
                resetPassword={false}
              />
            }
          />
          <Route path="/*" element={<Navigate to="/sign-in" replace />} />
          <Route
            path="/forgot-password"
            element={
              <AuthPage
                signIn={false}
                forgotPassword={true}
                resetPassword={false}
              />
            }
          />
          {isPasswordReset && (
            <Route
              path="/reset-password"
              element={
                <AuthPage
                  signIn={false}
                  forgotPassword={false}
                  resetPassword={true}
                />
              }
            />
          )}
        </>
      )}
    </Routes>
  );
};
