import { useQuery } from "@tanstack/react-query";

import { getUsersFeedback } from "../../services";

export const useGetUsersFeedback = (offset: number, limit: number, searchQuery: string) => {
  const {
    data: usersFeedback,
    isSuccess: isUsersFeedbackSuccess,
    isPending: usersFeedbackLoading,
    refetch: refetchUsersFeedback,
  } = useQuery({
    queryFn: () => getUsersFeedback(offset, limit, searchQuery),
    queryKey: ["usersFeedback"],
    staleTime: 500_000,
  });

  return {
    usersFeedback,
    isUsersFeedbackSuccess,
    refetchUsersFeedback,
    usersFeedbackLoading,
  };
};