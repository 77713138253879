import { Answer, Question } from "../types";

import { apiClient } from "../variables/vars";

import toast from "react-hot-toast";

export const fetchingQuestions = async (offset: number, limit: number, filters: { generatedWithAi: boolean }) => {
  try {
    const { data } = await apiClient.get(`/questions?offset=${offset}&limit=${limit}`);

    const questions: Question[] = [];

    for (const item of data.data) {
      const question = {
        id: item._id,
        questionText: item.text,
        book: item.book,
        answers: item.answers,
        chapter: item.chapter,
        verse: item.verse,
        priority: item.priority,
      };

      if (filters.generatedWithAi && !item.isGeneratedWithAi) continue;

      questions.push(question);
    }

    // console.log(questions, { offset, limit });

    return questions;
  } catch (error) {
    throw new Error("Failed to fetch questions from the server");
  }
};

export const fetchingSortedQuestions = async (
  bookId: string,
  chapterNumber: string,
  filters: { generatedWithAi: boolean }
) => {
  try {
    const { data } = await apiClient.get(`/questions/books/${bookId}/chapters/${chapterNumber}`);

    const questions: Question[] = [];

    for (const item of data.data) {
      const question = {
        id: item._id,
        questionText: item.text,
        book: item.book,
        answers: item.answers,
        chapter: item.chapter,
        verse: item.verse,
        priority: item.priority,
      };

      if (filters.generatedWithAi && !item.isGeneratedWithAi) continue;

      questions.push(question);
    }

    return questions;
  } catch (error) {
    throw new Error("Failed to fetch questions from the server");
  }
};

export const createQuestion = async ({
  bookId,
  bookChapter,
  difficulty,
  questionText,
  answers,
  verseIds,
  priority,
}: {
  bookId: string;
  bookChapter: number;
  difficulty: string;
  questionText: string;
  answers: Answer[];
  verseIds: string[];
  priority: number;
}) => {
  const res = await apiClient.post(
    "/questions",
    {
      text: questionText,
      answers: answers,
      bookId: bookId,
      chapter: bookChapter,
      verseId: verseIds,
      priority: priority,
      // difficulty
    },
  );

  return res;
};

export const changeQuestion = async ({
  question,
  bookId,
  bookName,
  bookChapter,
}: {
  question: Question;
  bookId: string;
  bookName: string;
  bookChapter: string;
}) => {
  try {
    await apiClient.put(
      `/questions/${question.id}`,
      {
        text: question.questionText,
        answers: question.answers,
        book: question.book,
        chapter: question.chapter,
        verse: question.verse,
        priority: question.priority,
      },
    );

    toast.success("Successfully changed");
  } catch (error: any) {
    if (error.message === "Request failed with status code 401") {
      toast.error("Unauthorized");
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const deleteQuestions = async (ids: string[]) => {
  try {
    await apiClient.delete("/questions", {
      data: ids,
    });

    toast.success("Successfully deleted");
  } catch (error: any) {
    if (error.message === "Request failed with status code 401") {
      toast.error("Unauthorized");
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const QuestionApiService = {
  fetchingQuestions,
  fetchingSortedQuestions,
  createQuestion,
  changeQuestion,
  deleteQuestions,
};
