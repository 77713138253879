import React from "react";

import { DropdownMenu, DropdownMenuItem } from "../../components";

import { MoreVertical, PencilLine, Trash2 } from "lucide-react";

import { RoomItem as RoomItemTypes } from "../../types";

import { Room } from "./RoomItems";

type RoomItemProps = {
  item: RoomItemTypes;
  onSelectRoom: (room: Room) => void;
  onOpenChangeModal: () => void;
  onOpenDeleteModal: () => void;
};

export const RoomItem: React.FC<RoomItemProps> = ({
  item,
  onSelectRoom,
  onOpenChangeModal,
  onOpenDeleteModal,
}) => {
  const menuBtn = (
    <div
      className="
        self-start text-gray-700 mt-[2px] text-center p-[8px] transition-all ease-in
        hover:text-gray-900
      "
    >
      <MoreVertical size={16}/>
    </div>
  );

  return (
    <li
      key={item.room._id}
      className="flex justify-between rounded-[14px] bg-white h-[120px]"
    >
      <div className="flex">
        <div className="bg-gray-100 w-[120px] rounded-l-[14px] flex items-center justify-center">
          {item.room.avatar ? (
            <img src={item.room.avatar}/>
          ) : (
            <p className="uppercase text-gray-500">{item.room.name[0]}</p>
          )}
        </div>
        <div className="p-[10px] flex flex-col justify-between">
          <p className="font-semibold line-clamp-2" title={item.room.name}>{item.room.name}</p>
          <p className="text-[14px] text-gray-700">users count: {item.usersCount}</p>
        </div>
      </div>

      <DropdownMenu
        menuBtn={menuBtn}
      >
        <DropdownMenuItem
          onClick={() => {
            onSelectRoom({
              id: item.room._id,
              name: item.room.name,
              description: item.room.description,
            });
            onOpenChangeModal();
          }}
        >
          <div className="flex gap-[10px] items-center">
            <PencilLine size={16}/>
            <span>Change</span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            onSelectRoom({
              id: item.room._id,
              name: item.room.name,
              description: item.room.description,
            });
            onOpenDeleteModal();
          }}
        >
          <div className="flex gap-[10px] items-center">
            <Trash2 size={16}/>
            <span>Delete</span>
          </div>
        </DropdownMenuItem>
      </DropdownMenu>
    </li>
  );
};