import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { AxiosResponse } from "axios";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export interface BaseServerResponse<T> {
  data: any;
  status: "success" | string;
};

export const formatApiResponse = (
  response: AxiosResponse<BaseServerResponse<any>>,
) => {
  return response.data;
};
