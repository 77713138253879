import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { AuthContextProvider } from "./context/auth-context";
import { QuestionsContextProvider } from "./context/questions-context";
import { FullOpenNavbarContextProvider } from "./context/full-open-navbar";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./index.css";

const client = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <AuthContextProvider>
          <QuestionsContextProvider>
            <FullOpenNavbarContextProvider>
              <App />
            </FullOpenNavbarContextProvider>
          </QuestionsContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);
