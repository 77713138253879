import React from "react";

import { Loader as LoaderIcon } from "lucide-react";

import { cn } from "../../../lib/utils";

export const Loader = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        "text-gray-400 w-full h-full flex items-center justify-center",
        className,
      )}
    >
      <LoaderIcon size={20} className="animate-spin" />
    </div>
  );
};
