import React, { useContext, useState } from "react";

import { RoomItem as RoomItemTypes } from "../../types";

import { deleteRoom } from "../../services";
import { useMutation } from "@tanstack/react-query";

import FullOpenNavbarContext from "../../context/full-open-navbar";

import {
  Loader,
  Button,
  MainModal,
} from "../../components";
import { RoomForm } from "./RoomForm";
import { RoomItem } from "./RoomItem";

import { Loader as LoaderIcon } from "lucide-react";

import toast from "react-hot-toast";

type RoomItemsProps = {
  roomItems: RoomItemTypes[];
  loading: boolean;
  refetchRooms: () => void;
};

export type Room = {
  id: string;
  name: string;
  description: string;
};

export const RoomItems: React.FC<RoomItemsProps> = ({ roomItems, loading, refetchRooms }) => {
  const { isFullOpened } = useContext(FullOpenNavbarContext);
  const [openChangeRoomModal, setOpenChangeRoomModal] = useState(false);
  const [openDeleteRoomModal, setOpenDeleteRoomModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);

  const selectRoomHandler = (room: Room) => {
    setSelectedRoom(room);
  };

  const { mutate: deleteRoomMutation, isPending: isDeletePending } = useMutation({
    mutationFn: (id: string) => deleteRoom(id),
    onSuccess: () => {
      setOpenDeleteRoomModal(false);

      refetchRooms();
      toast.success("Successfully changed room");
    },
    onError: () => {
      toast.error("Something went wrong");
    }
  });

  const deleteRoomHandler = () => {
    deleteRoomMutation(selectedRoom?.id ?? "");
  };

  return (
    <>
      <div>
        <p className="text-[24px] font-semibold">List of rooms</p>

        {loading ? (
          <div className="m-auto my-[40px]">
            <Loader/>
          </div>
        ) : (
          <ul
            className={`
            ${isFullOpened && "lg:grid-cols-1 xl:grid-cols-2"} 
            w-full grid gap-[8px] grid-cols-1 pt-[5px] sm:grid-cols-2
            lg:grid-cols-2 2xl:grid-cols-3
          `}
          >
            {roomItems.map(item => (
              <RoomItem
                key={item.room._id}
                item={item}
                onSelectRoom={selectRoomHandler}
                onOpenChangeModal={() => setOpenChangeRoomModal(true)}
                onOpenDeleteModal={() => setOpenDeleteRoomModal(true)}
              />
            ))}
          </ul>
        )}
      </div>

      {!loading && roomItems.length === 0 && <p className="mx-auto]">No rooms yet</p>}

      <MainModal
        title="Change room"
        isOpen={openChangeRoomModal}
        onClose={() => setOpenChangeRoomModal(false)}
      >
        <RoomForm
          id={selectedRoom?.id}
          name={selectedRoom?.name ?? ""}
          description={selectedRoom?.description ?? ""}
          onCloseModal={() => setOpenChangeRoomModal(false)}
          refetchRooms={refetchRooms}
        />
      </MainModal>

      <MainModal
        title="Deleting user"
        description="Are you sure? This action cannot be undone."
        isOpen={openDeleteRoomModal}
        onClose={() => setOpenDeleteRoomModal(false)}
        className="w-[400px] relative mt-[-50px] deleting-modal"
      >
        <div className="absolute py-[10px] px-[20px] left-0 right-0 w-full bg-gray-100 min-h-[50px] rounded-b-[15px]">
          <div className="flex justify-end items-center gap-[8px]">
            <Button
              onClick={() => setOpenDeleteRoomModal(false)}
              className="bg-white py-[8px] text-gray-700 hover:bg-white"
            >
              Cancel
            </Button>
            <Button
              onClick={deleteRoomHandler}
              disabled={isDeletePending}
              className="bg-red-400 py-[8px] w-[100px] hover:bg-red-300 disabled:bg-red-300"
            >
              {isDeletePending ? (
                <LoaderIcon className="animate-spin my-[3px]" size={18} />
              ) : (
                <span>Delete</span>
              )}
            </Button>
          </div>
        </div>
      </MainModal>
    </>
  );
};