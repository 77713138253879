import { useQuery } from "@tanstack/react-query";
import { fetchingBibleBooks } from "../../services";

export const useGetBibleBooks = () => {
  const { data: bibleBooks, isSuccess: isBibleBooksSuccess, isPending: isBibleBookPending } = useQuery({
    queryFn: () => fetchingBibleBooks(),
    queryKey: ["bibleBooks"],
  });

  return {
    isBibleBooksSuccess,
    bibleBooks: bibleBooks || [],
    isBibleBookPending,
  };
};
