import React, { useEffect, useContext } from "react";

import AuthContext from "../../context/auth-context";

import { useNavigate, useParams } from "react-router-dom";

import { CardBox, UsersTable } from "../../components";

// import Stack from "@mui/material/Stack";
// import Pagination from "@mui/material/Pagination";

export const UsersPage = () => {
  const navigate = useNavigate();
  const currentPage = Number(useParams().page);
  const { fetchingUsers, users} = useContext(AuthContext);
  const paginationCounter = Math.floor(users.length / 10) + 1;

  const paginationChangingHandler = (
    e: React.ChangeEvent<any>,
    value: number,
  ) => {
    navigate(`/admin/users/${value}`);

    const bodyElement = document.querySelector("body");

    if (bodyElement !== null) {
      bodyElement.scrollTop = 0;
    }

    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    // const usersOffset = currentPage * 10 - 10;
    // const usersLimit = currentPage * 10;

    // fetchingUsers(usersOffset, usersLimit);
    fetchingUsers(0, 10000);
  }, [currentPage]);

  return (
    <CardBox className="p-[30px] mx-auto h-[calc(100dvh-68.6px)] lg:h-[calc(100vh-20px)] flex flex-col justify-between">
      <div className="h-[90%] overflow-hidden">
        <h1 className="text-[25px] font-semibold">Registered users</h1>
        <p>Total users - {users.length}</p>
        <UsersTable users={users} />
      </div>
      {/*{paginationCounter > 1 && (*/}
      {/*  <div className="flex justify-center items-end h-[10%]">*/}
      {/*    <Stack spacing={2}>*/}
      {/*      <Pagination*/}
      {/*        count={paginationCounter}*/}
      {/*        page={currentPage}*/}
      {/*        sx={{*/}
      {/*          "& .MuiPaginationItem-root": {*/}
      {/*            "&.Mui-selected": {*/}
      {/*              backgroundColor: "#111827",*/}
      {/*              color: "white",*/}
      {/*            },*/}
      {/*            "&.Mui-selected:hover": {*/}
      {/*              backgroundColor: "#111827",*/}
      {/*              color: "white",*/}
      {/*            },*/}
      {/*          },*/}
      {/*        }}*/}
      {/*        onChange={(e, value) => paginationChangingHandler(e, value)}*/}
      {/*      />*/}
      {/*    </Stack>*/}
      {/*  </div>*/}
      {/*)}*/}
    </CardBox>
  );
};
