import React, { useContext, useEffect, useState } from "react";

import { Question, SelectItem } from "../../types";

import QuestionsContext from "../../context/questions-context";

import { useGetBibleBooks } from "../../hooks";

import { Select, Input, Button, CardBox } from "../../components";
import { AlertTriangle, Check, Loader } from "lucide-react";

import {v4 as uuidv4} from "uuid";

import toast from "react-hot-toast";

type ChangingQuestionFormProps = {
  questionId: string;
  book: string;
  chapter: number;
  difficulty: string;
  questionText: string;
  answers: { text: string; isRight: boolean }[];
  onCloseModal: () => void;
  currentBook: {
    bookId: string;
    bookChapter: string;
    bookName: string;
  };
  onChangeCurrentQuestion: ({
    question,
    bookId,
    bookName,
    bookChapter,
  } : {
    question: Question;
    bookId: string;
    bookName: string;
    bookChapter: string;
  }) => void;
  verse: string[];
  priority: number;
  submitLoading: boolean;
};

type QuestionFormTypes = {
  selectedBook: SelectItem;
  chapter: string;
  verses: {
    startingVerse: string;
    endingVerse: string;
  };
  priority: string;
  questionText: string;
  answers: { id: string; text: string; isRight: boolean }[];
};

export const ChangingQuestionForm: React.FC<ChangingQuestionFormProps> = ({
  questionId,
  book,
  chapter,
  difficulty,
  questionText,
  answers,
  onCloseModal,
  currentBook,
  onChangeCurrentQuestion,
  verse,
  priority,
  submitLoading,
}) => {
  const { getVerses, currentVerses} = useContext(QuestionsContext);
  const { bibleBooks } = useGetBibleBooks();

  const [chaptersCount, setChaptersCount] = useState(0);
  // const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<QuestionFormTypes>({
    selectedBook: {
      id: book,
      name: "Empty",
    },
    chapter: chapter.toString(),
    verses: {
      startingVerse: "1",
      endingVerse: "1",
    },
    priority: priority.toString(),
    questionText: questionText,
    answers: answers.map(item => {
      return {
        id: uuidv4(),
        text: item.text,
        isRight: item.isRight,
      };
    }),
  });

  const bibleBooksChangeHandler = (value: { id: string; name: string; }) => {
    for (let book of bibleBooks || []) {
      if (value.id === book.id) {
        setChaptersCount(book.chaptersCount);
        setFormData({
          ...formData,
          selectedBook: {
            id: value.id,
            name: value.name,
            bookNumber: book.bookNumber,
            chaptersCount: book.chaptersCount,
            sortingOrder: book.sortingOrder,
          },
          verses: {
            startingVerse: "1",
            endingVerse: "1",
          },
          chapter: "1",
        });
      }
    }
  };

  const chapterChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (
      inputValue === "" ||
      (parseInt(inputValue) <= Number(chaptersCount) && parseInt(inputValue) >= 1)
    ) {
      setFormData({
        ...formData,
        chapter: inputValue,
        verses: {
          startingVerse: "1",
          endingVerse: "1",
        },
      });
    }
  };

  const startingVerseChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (
      inputValue === "" ||
      (parseInt(inputValue) <= Number(currentVerses.length) && parseInt(inputValue) >= 1)
    ) {
      if (Number(formData.verses.startingVerse) >= Number(formData.verses.endingVerse)) {
        setFormData({
          ...formData,
          verses: {
            startingVerse: inputValue,
            endingVerse: inputValue,
          }
        });
      } else {
        setFormData({
          ...formData,
          verses: {
            ...formData.verses,
            startingVerse: inputValue,
          }
        });
      }
    }
  };

  const endingVerseChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (
      inputValue === "" ||
      (parseInt(inputValue) <= Number(currentVerses.length) && parseInt(inputValue) >= 1)
    ) {
      setFormData({
        ...formData,
        verses: {
          ...formData.verses,
          endingVerse: inputValue,
        }
      });
    }
  };

  const answerTextChangeHandler = (id: string, newText: string) => {
    setFormData(prevFormData => {
      const updatedAnswers = prevFormData.answers.map(answer => {
        if (answer.id === id) {
          return { ...answer, text: newText };
        }

        return answer;
      });

      return { ...prevFormData, answers: updatedAnswers };
    });
  };

  const answerCheckChangeHandler = (id: string) => {
    setFormData(prevFormData => {
      const updatedAnswers = prevFormData.answers.map(answer => ({
        ...answer,
        isRight: answer.id === id,
      }));

      return { ...prevFormData, answers: updatedAnswers };
    });
  };

  const submitQuestionFormHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const checkAnswers = () => {
      for (const answer of formData.answers) {
        if (answer.text === "") return false;
      }

      return true;
    };

    if (formData.questionText === "" || !checkAnswers()) {
      toast(t => (
        <div className="flex items-center gap-[8px]">
          <AlertTriangle
            size={18}
            strokeWidth={2.2}
            className="text-orange-300"
          />
          <div>Some fields are empty</div>
        </div>
      ));

      return;
    }

    // if (selectedBook) {
    const vIds = [];

    for (let i = 0; i < currentVerses.length; i++) {
      if (i >= (Number(formData.verses.startingVerse) - 1) && i <= (Number(formData.verses.endingVerse) - 1)) {
        vIds.push(currentVerses[i]._id);
      }
    }

    const questionAnswers = formData.answers.map(answer => ({
      text: answer.text.toUpperCase(),
      isRight: answer.isRight,
    }));

    const formQuestion = {
      id: questionId,
      book: formData.selectedBook.id,
      questionText: formData.questionText.trim(),
      answers: questionAnswers,
      chapter: Number(formData.chapter),
      verse: vIds,
      priority: Number(formData.priority),
    };

    onChangeCurrentQuestion({
      question: formQuestion,
      bookId: currentBook.bookId,
      bookName: currentBook.bookName,
      bookChapter: currentBook.bookChapter,
    });
    // }
  };

  const findNeededBibleBook = () => {
    for (const item of bibleBooks) {
      if (item.id === book) {
        setChaptersCount(item.chaptersCount);
        setFormData(prevFormData => ({
          ...prevFormData,
          selectedBook: {
            id: item.id,
            name: item.name,
          },
        }));
        getVerses(item.bookNumber, Number(formData.chapter));
      }
    }
  };

  useEffect(() => {
    if (bibleBooks.length > 0) {
      findNeededBibleBook();
    }
  }, [bibleBooks]);

  const findNeededVerses = () => {
    if (currentVerses.length !== 0) {
      const startingIndex = currentVerses.findIndex(cVerse => cVerse._id === verse[0]);
      const endingIndex = currentVerses.findIndex(cVerse => cVerse._id === verse.at(-1));

      if (startingIndex !== -1 && endingIndex === -1) {
        setFormData(prevFormData => ({
          ...prevFormData,
          verses: {
            ...formData.verses,
            startingVerse: (startingIndex + 1).toString(),
          }
        }));
      } else if (endingIndex !== -1 && startingIndex === -1) {
        setFormData(prevFormData => ({
          ...prevFormData,
          verses: {
            ...formData.verses,
            endingVerse: (endingIndex + 1).toString(),
          }
        }));
      } else if (startingIndex !== -1 && endingIndex !== -1) {
        setFormData(prevFormData => ({
          ...prevFormData,
          verses: {
            startingVerse: (startingIndex + 1).toString(),
            endingVerse: (endingIndex + 1).toString(),
          }
        }));
      }
    }
  };

  useEffect(() => {
    findNeededVerses();
    // setLoading(false);
  }, [currentVerses]);

  return (
    <div>
      <form onSubmit={submitQuestionFormHandler}>
        <div>
          <div className="gap-[8px] sm:grid sm:grid-cols-2">
            <div className="flex-1">
              <label className="font-semibold block mb-[8px]">Book</label>
              <Select
                listData={bibleBooks || []}
                selectedValue={formData.selectedBook}
                onChangeSelect={(value) => bibleBooksChangeHandler(value)}
                className="mt-[8px]"
              />
            </div>
            <div className="flex-1">
              <label className="font-semibold">Chapter</label>
              <Input
                type="number"
                min="1"
                max={chaptersCount}
                value={formData.chapter}
                onChange={chapterChangeHandler}
                className="w-full mt-[8px] border-transparent border-[2px]"
              />
            </div>
            <div className="flex-1">
              <label className="font-semibold block mb-[8px]">Starting verse</label>
              <Input
                type="number"
                min="1"
                max={currentVerses.length}
                value={formData.verses.startingVerse}
                onChange={startingVerseChangeHandler}
                className="w-full"
              />
            </div>
            <div className="flex-1">
              <label className="font-semibold block mb-[8px]">Ending verse</label>
              <Input
                type="number"
                min={formData.verses.startingVerse}
                max={currentVerses.length}
                value={formData.verses.endingVerse}
                onChange={endingVerseChangeHandler}
                className="w-full"
              />
            </div>
            <div className="flex-1">
              <label className="font-semibold">Priority</label>
              <Input
                type="number"
                min="1"
                value={formData.priority}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    priority: (event.target.value).toString(),
                  })
                }}
                className="w-full mt-[8px] border-transparent border-[2px]"
              />
            </div>
            <div className="flex-1">
              <label className="font-semibold block mb-[8px]">Difficulty</label>
              <Select
                listData={[{id: "level-1", name: "level-1"}]}
                selectedValue={{id: "level-1", name: "level-1"}}
                onChangeSelect={(value: { id: string; name: string }) =>
                  console.log(value)
                }
              />
            </div>
          </div>

          <div className="flex justify-center mt-[8px]">
            <div className="w-full">
              <label className="font-semibold">Question</label>
              <textarea
                value={formData.questionText}
                onChange={event => {
                  setFormData({
                    ...formData,
                    questionText: event.target.value,
                  })
                }}
                className="
                  block px-[15px] py-[10px] mt-[8px] rounded-[10px] bg-gray-100 border-transparent border-[2px]
                  w-full max-w-full min-h-[130px] max-h-[400px] focus:transition-all focus:ease-in-out
                  placeholder:text-gray-400 focus:border-[2px] focus:border-blue-400
                "
              />
            </div>
          </div>

          <ul className="mt-[8px] flex flex-wrap gap-[10px] sm:grid sm:grid-cols-2 sm:gap-[10px]">
            {formData.answers.map((answer, index) => (
              <li key={answer.id} className="block w-full">
                <CardBox className="w-full shadow-none p-0 pt-[10px]">
                  <div className="flex items-center gap-[8px] mb-4">
                    <div
                      className={`
                        ${answer.isRight && "bg-blue-400 text-white border-white"}
                        border-[1px] border-gray-200 p-[2px] text-white cursor-pointer
                        rounded-[5px] w-[19px] h-[19px] flex items-center justify-center
                        focus:border-[2px] focus:border-red-500
                      `}
                      onClick={() => answerCheckChangeHandler(answer.id)}
                    >
                      <Check size={22} strokeWidth={2.2}/>
                    </div>
                    <input
                      id={`answer-check-${index}`}
                      type="radio"
                      name="is-right"
                      className="hidden"
                      checked={answer.isRight}
                      onChange={() => answerCheckChangeHandler(answer.id)}
                    />
                    <label
                      htmlFor={`answer-check-${index}`}
                      className="font-semibold"
                    >
                      Right answer
                    </label>
                  </div>

                  <textarea
                    className="
                      block px-[15px] py-[10px] mt-[8px] rounded-[10px] bg-gray-100 border-transparent border-[2px]
                      w-full max-w-full min-h-[130px] max-h-[400px] uppercase focus:transition-all focus:ease-in-out
                      placeholder:text-gray-400 focus:border-[2px] focus:border-blue-400
                    "
                    placeholder="Enter answer"
                    value={answer.text}
                    onChange={event => {
                      answerTextChangeHandler(answer.id, event.target.value);
                    }}
                  />
                </CardBox>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex gap-[8px] justify-end mt-[8px]">
          <Button
            onClick={onCloseModal}
            className="bg-white py-[8px] border-[1px] border-gray-400 text-gray-700 hover:bg-white"
            type="button"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="w-[96px]"
            disabled={submitLoading}
          >
            {submitLoading ? (
              <Loader
                className="animate-spin my-[3px]"
                size={18}
              />
            ) : (
              <span>Change</span>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};
