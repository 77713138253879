import { apiClient } from "../variables/vars";

import { BaseServerResponse } from "../lib/utils";

import { RoomItem } from "../types";

export const getRooms = async (offset: number, limit: number, name: string) => {
  try {
    const response = await apiClient.get<BaseServerResponse<RoomItem[]>>(
      `/rooms?offset=${offset}&limit=${limit}&searchQuery=${name}`,
    );

    const data = response.data.data;
    const rooms: RoomItem[] = [];

    if (data.rooms) {
      for (const item of data.rooms) {
        rooms.push(item);
      }
    }

    return {
      rooms,
      roomsCount: data.roomsCount,
    };
  } catch (error) {
    console.log(error);
  }
};

export const addRoom = async (name: string, description: string) => {
  const res = await apiClient.post("/rooms",
    {
      // userId: userId,
      roomName: name,
      roomDescription: description,
      roomAvatar: null,
    }
  );

  return res;
};

export const changeRoom = async (id: string, name: string, description: string) => {
  const res = await apiClient.put(`/rooms/${id}`,
    {
      name,
      description,
    }
  );

  return res;
};

export const deleteRoom = async (id: string) => {
  const res = await apiClient.delete(`/rooms/${id}`);

  return res;
};

export const RoomsApiService = {
  getRooms,
  addRoom,
  changeRoom,
  deleteRoom,
};