import React, { HTMLAttributes } from "react";

import { Menu } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
};

interface DropdownMenuItemProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const DropdownMenuItem: React.FC<DropdownMenuItemProps>  = ({
  children,
 ...rest
}: {
  children: React.ReactNode;
}) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <div
          {...rest}
          className={classNames(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "block px-4 py-2 text-sm cursor-pointer",
          )}
        >
          {children}
        </div>
      )}
    </Menu.Item>
  );
};
