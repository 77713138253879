import { deleteUserFeedback } from "../../services";

import { useMutation } from "@tanstack/react-query";

import toast from "react-hot-toast";

export const useDeleteUserFeedback = () => {
  const {
    mutate: deleteFeedback,
    isSuccess: isDeleteSuccess,
    isPending: isDeletePending,
  } = useMutation({
    mutationFn: deleteUserFeedback,
    onError: () => {
      toast.error("Something went wrong");
    }
  });

  return {
    deleteFeedback,
    isDeleteSuccess,
    isDeletePending,
  };
};