import React, { useState, useEffect, useCallback } from "react";

import { User } from "../types";

import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

import axios from "axios";

import { jwtDecode } from "jwt-decode";

import { mainPath } from "../variables/vars";

let logoutTimer: any;

type AuthData = {
  email?: string;
  password?: string;
  newPassword?: string;
  code?: string;
};

type AuthContextTypes = {
  token: string | null | undefined;
  isLoggedIn: boolean;
  auth: (
    signIn: boolean,
    forgotPassword: boolean,
    resetPassword: boolean,
    data: AuthData,
  ) => void;
  logout: () => void;
  loading: boolean;
  isPasswordReset: boolean;
  fetchingUsers: (offset: number, limit: number) => void;
  users: User[];
  usersLoading: boolean;
};

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  auth: (
    signIn,
    forgotPassword,
    resetPassword,
    data,
  ) => {},
  logout: () => {},
  fetchingUsers: (offset, limit) => {},
} as AuthContextTypes);

const calculateRemainingTime = (expirationTime: string) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");
  const userData = localStorage.getItem("userData");
  const refreshToken = localStorage.getItem("refreshToken");

  const remainingTime = calculateRemainingTime(storedExpirationDate ?? "");

  if (remainingTime <= 3600) {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expirationTime");

    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
    // storedId: storedId,
    userData: userData,
    refreshToken,
  };
};

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState<User[]>([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const tokenData = retrieveStoredToken();

  let initialToken;

  if (tokenData) initialToken = tokenData.token;

  const [token, setToken] = useState(initialToken);

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);

    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userData");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (
    token: string,
    expirationTime: string,
    refreshToken: string,
  ) => {
    setToken(token);

    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("expirationTime", expirationTime.toString());

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const auth = async (
    signIn: boolean,
    forgotPassword: boolean,
    resetPassword: boolean,
    data: AuthData,
  ) => {
    let path = "";

    if (signIn) {
      path = `${mainPath}/sign-in`;
    } else if (forgotPassword) {
      path = `${mainPath}/request-code/email?flow=forgot`;
    } else if (resetPassword) {
      path = `${mainPath}/forgot-password`;
    }

    try {
      setIsLoading(true);

      if (signIn) {
        const userData = await axios.post(path, data);
        const token = userData.data.data.accessToken;
        const expirationTime = jwtDecode(token).exp;

        if (expirationTime) {
          const date = new Date(expirationTime * 1000);
          const formattedDate = date.toISOString();

          loginHandler(token, formattedDate, userData.data.data.refreshToken);
        }

        toast.success("Authentication success");
        navigate("/admin/dashboard");
      } else if (forgotPassword) {
        await axios.post(path, data);

        toast("Email sent");
        setIsPasswordReset(true);
        navigate("/reset-password");
      } else if (resetPassword) {
        const resetPasswordData = {
          email: data.email,
          newPassword: data.newPassword,
          code: data.code,
        };

        await axios.post(path, resetPasswordData);

        toast.success("Email has been successfully changed");
      }
    } catch (error: any) {
      let errorMessage = error.response.data.error.errors[0].message;

      toast.error(errorMessage || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  // getting users
  const fetchingUsers = async (offset: number, limit: number) => {
    try {
      setUsersLoading(true);

      const { data } = await axios.get(
        `${mainPath}/customers?offset=${offset}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        },
      );

      const usersList = data.data;
      const arr = [];

      for (const user of usersList) {
        const newUser = {
          id: user._id,
          name: user.fullName,
          createdDate: user.createdAt,
          email: user.email,
        };

        arr.push(newUser);
      }

      setUsers(arr);
    } catch (error: any) {
      return toast.error(error.message || "Something went wrong");
    } finally {
      setUsersLoading(false);
    }
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    user: user,
    users: users,
    isLoggedIn: userIsLoggedIn,
    auth,
    logout: logoutHandler,
    fetchingUsers,
    usersLoading,
    loading: isLoading,
    isPasswordReset: isPasswordReset,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
