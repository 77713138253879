import { CardBox } from "../../components";

export const DashboardPage = () => {
  return (
    <div className="h-[calc(100vh-20px)]">
      <CardBox>
        <h1 className="font-semibold text-[25px]">Dashboard</h1>
      </CardBox>
      <div
        className="flex items-center justify-center text-gray-400"
        style={{ height: "calc(100% - 84px)" }}
      >
        <p>Nothing here yet</p>
      </div>
    </div>
  );
};
