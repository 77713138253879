import { BibleBook } from "../types";

import { apiClient } from "../variables/vars";

export const fetchingBibleBooks = async () => {
  try {
    const { data } = await apiClient.get("/bible/books");

    const books = data.data;
    const booksArr: BibleBook[] = [];

    if (data.data) {
      for (let book of books) {
        const bibleBook = {
          id: book._id,
          bookNumber: book.book_number,
          chaptersCount: book.chapters_count,
          name: book.long_name,
          sortingOrder: book.sorting_order,
        };

        booksArr.push(bibleBook);
      }
    }

    return booksArr;
  } catch (error) {
    console.log(error);
  }
};
