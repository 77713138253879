import React from "react";

import { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDown } from "lucide-react";

type DropdownMenuProps = {
  children: React.ReactNode;
  menuBtn: string | React.ReactNode;
  onSelect?: (event: any) => void;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  onSelect,
  children,
  menuBtn,
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          onClick={onSelect}
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md text-sm font-semibold text-gray-900"
        >
          {menuBtn}
          {/*<ChevronDown className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 w-[150px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
