import { UserFeedback } from "../types";

import { apiClient } from "../variables/vars";

export const getUsersFeedback = async (offset: number, limit: number, searchQuery: string) => {
  try {
    const { data } = await apiClient.get(`/feedback?offset=${offset}&limit=${limit}&searchQuery=${searchQuery}`);

    const feedback: UserFeedback[] = [];

    for (let user of data.data) {
      const userFeedback = {
        id: user._id,
        email: user.email,
        text: user.text,
        type: user.type ?? "feedback",
        userName: user.userName,
        createdDate: user.createdAt,
      }

      feedback.unshift(userFeedback);
    }

    return feedback;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch users feedback from the server");
  }
}

export const deleteUserFeedback = async (id: string) => {
  try {
    await apiClient.delete(`/feedback/${id}`);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to delete user");
  }
}