import React, { useState } from "react";

import { Question } from "../../types";

import {
  DataGrid,
  GridColumnMenu,
  GridColDef,
  GridColumnMenuProps,
} from "@mui/x-data-grid";

import { Loader } from "../../components";
import { Pagination, Stack } from "@mui/material";

type MuiDataTableProps = {
  rows: Question[];
  columns: GridColDef[];
  selectRowsId: (id: string[]) => void;
  loading: boolean;
  openQuestion: (questionValue: any) => void;
  onGetPaginationValues: (value: { pageSize: number; page: number }) => void;
  rowsCount: number;
  isFiltered: boolean;
};

const LoadingItem = () => <Loader className="bg-white" />;

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
};

export const MuiDataTable: React.FC<MuiDataTableProps> = ({
  selectRowsId,
  rows,
  columns,
  loading,
  openQuestion,
  onGetPaginationValues,
  rowsCount,
  isFiltered,
}) => {
  const pagesCount = Math.floor(rowsCount / 100);
  const [currentPage, setCurrentPage] = useState(1);

  const paginationChangeHandler = (
    _event: React.ChangeEvent<any>,
    value: number,
  ) => {
    setCurrentPage(value);
    onGetPaginationValues({ page: value - 1, pageSize: 100 });
  };

  return (
    <div className="h-[88.6%] w-full">
      <DataGrid
        rows={rows}
        // rowCount={rowsCount}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        // onPaginationModelChange={(value) => {
        //   onGetPaginationValues(value);
        //   setPagination(value);
        // }}
        pageSizeOptions={[25, 50, 100]}
        hideFooter
        checkboxSelection
        onStateChange={items => selectRowsId(items.rowSelection)}
        onRowClick={value => openQuestion(value)}
        loading={loading}
        slots={{
          loadingOverlay: LoadingItem,
          columnMenu: CustomColumnMenu,
        }}
        disableRowSelectionOnClick
        getRowHeight={() => "auto"}
        sx={{
          ".MuiDataGrid-columnHeaders": {
            borderTop: "1px solid rgb(229 231 235)",
            borderBottom: "1px solid rgb(229 231 235)",
            borderRadius: 0,
          },
          "& .MuiDataGrid-checkboxInput": {
            "&.Mui-checked": {
              color: "rgb(96 165 250)",
            },
          },
          ".MuiDataGrid-checkboxInput svg": {
            width: 18,
            height: 18,
          },
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
        }}
        style={{ borderLeft: "none", borderRight: "none", borderTop: "none" }}
      />

      {pagesCount > 0 && !isFiltered && (
        <div className="flex justify-end items-end mt-[10px] h-[5%]">
          <Stack spacing={2}>
            <Pagination
              count={pagesCount}
              page={currentPage}
              size="small"
              sx={{
                "& .MuiPaginationItem-root": {
                  "&.Mui-selected": {
                    backgroundColor: "#111827",
                    color: "white",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "#111827",
                    color: "white",
                  },
                },
              }}
              onChange={(e, value) => paginationChangeHandler(e, value)}
            />
          </Stack>
        </div>
      )}
    </div>
  );
};