import React, { useContext } from "react";

import { Route, Routes } from "react-router-dom";

import FullOpenNavbarContext from "../../context/full-open-navbar";

import { Navbar } from "../../components";

import {
  DashboardPage,
  QuestionsPage,
  AddQuestionPage,
  NotFoundPage,
  ProfilePage,
  UsersFeedback,
  Rooms,
} from "../../pages";

import { UsersPageRouter } from "../../router";

export const MainPage = () => {
  const { isFullOpened } = useContext(FullOpenNavbarContext);

  return (
    <div
      className={`
        ${isFullOpened ? "lg:pl-[340px]" : "lg:pl-[100px]"}
        h-[100%] py-[10px] pl-[10px] pr-[10px]
      `}
    >
      <Navbar />
      <Routes>
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/users/*" element={<UsersPageRouter />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/questions" element={<QuestionsPage />} />
        <Route path="/questions/add-question" element={<AddQuestionPage />} />
        <Route path="/users-feedback" element={<UsersFeedback />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};
