import axios from "axios";

export const mainPath = process.env.REACT_APP_BACKEND_MAIN_PATH;
export const authorizationHeader = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "application/json",
};

export const apiClient = axios.create({
  baseURL: mainPath,
  headers: authorizationHeader,
});
