import React, { useEffect, useState, useTransition } from "react";

import { useDeleteUserFeedback, useGetUsersFeedback } from "../../hooks";

import { MainModal, Button, CardBox, Input } from "../../components";
import { UsersFeedbackRow } from "./UsersFeedbackRow";

import { Loader, Search } from "lucide-react";

export const UsersFeedback = () => {
  const { deleteFeedback, isDeleteSuccess, isDeletePending } = useDeleteUserFeedback();
  const [searchFeedback, setSearchFeedback] = useState("");
  const [isPending, startTransition] = useTransition();
  const [userId, setUserId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const tableHead = ["Name", "Created date", "Email", "Message", ""];

  const deleteUserHandler = () => {
    deleteFeedback(userId);
  };

  const {
    usersFeedback,
    usersFeedbackLoading,
    refetchUsersFeedback,
  } = useGetUsersFeedback(0, 10000, searchFeedback);

  const changeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFeedback(event.target.value);
  };

  useEffect(() => {
    startTransition(() => {
      refetchUsersFeedback();
    });
  }, [searchFeedback]);

  useEffect(() => {
    if (!isDeletePending && isDeleteSuccess) {
      refetchUsersFeedback();
      setModalOpen(false);
    }
  }, [isDeletePending, isDeleteSuccess]);

  return (
    <>
      <CardBox
        className="
          flex gap-[10px] justify-between flex-col
          sm:gap-[20px] sm:flex-row sm:items-center
          sm:justify-start
        "
      >
        <h1 className="font-semibold text-[25px]">Users feedback</h1>
        <div className="flex relative">
          <Input
            className="pr-[50px] w-full sm:w-[380px]"
            placeholder="Search user"
            value={searchFeedback}
            onChange={changeInputHandler}
          />
          <div
            className="
              flex items-center justify-center absolute top-[2px] right-[3px]
              px-[15px] py-[11px] bg-gray-100 rounded-r-[10px] text-gray-500
            "
          >
            <Search size={20} />
          </div>
        </div>
      </CardBox>

      <CardBox className="mt-[10px] p-[30px] overflow-auto h-[calc(100dvh-196px)] lg:h-[calc(100vh-118px)]">
        <div className="h-[100%] overflow-auto">
          <table className="w-[1000px] table-auto xl:w-full">
            <thead className="p-[20px] border-gray-200 border-y-[1px]">
            <tr className="text-left">
              {tableHead.map((item) => (
                <th
                  key={Math.random()}
                  className="first:block font-semibold first:py-[10px] text-gray-400"
                >
                  {item}
                </th>
              ))}
            </tr>
            </thead>

            {usersFeedback && (
              <tbody>
                {(!isPending || !usersFeedbackLoading) && usersFeedback.map((user) => (
                  <UsersFeedbackRow
                    key={user.id}
                    id={user.id}
                    name={user.userName}
                    createdDate={user.createdDate}
                    email={user.email}
                    message={user.text}
                    openDeletingModal={() => {
                      setModalOpen(true);
                      setUserId(user.id);
                    }}
                  />
                ))}
              </tbody>
            )}
          </table>

          {usersFeedback?.length === 0 && !usersFeedbackLoading && (
            <p className="text-center text-gray-300 mt-[100px]">No users</p>
          )}
          {(usersFeedbackLoading || isPending) && (
            <div className="h-1/2 flex items-center justify-center text-gray-400">
              <Loader size={20} className="animate-spin"/>
            </div>
          )}
        </div>
      </CardBox>

      <MainModal
        title="Deleting user"
        description="Are you sure? This action cannot be undone."
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        className="w-[400px] relative mt-[-50px] deleting-modal"
      >
        <div className="absolute py-[10px] px-[20px] left-0 right-0 w-full bg-gray-100 min-h-[50px] rounded-b-[15px]">
          <div className="flex justify-end items-center gap-[8px]">
            <Button
              onClick={() => setModalOpen(false)}
              className="bg-white py-[8px] text-gray-700 hover:bg-white"
            >
              Cancel
            </Button>
            <Button
              onClick={deleteUserHandler}
              disabled={isDeletePending}
              className="bg-red-400 py-[6px] w-[100px] hover:bg-red-300 disabled:bg-red-300"
            >
              {isDeletePending ? (
                <Loader className="animate-spin my-[3px]" size={18} />
              ) : (
                <span>Delete</span>
              )}
            </Button>
          </div>
        </div>
      </MainModal>
    </>
  );
};