import React, { useEffect, useState } from "react";

type FullOpenNavbarContextTypes = {
  isFullOpened: boolean;
  openFullNavbar: () => void;
  closeFullNavbar: () => void;
};

const FullOpenNavbarContext = React.createContext({} as FullOpenNavbarContextTypes);

export const FullOpenNavbarContextProvider = ({ children } : { children: React.ReactNode }) => {
  const [isFullOpened, setIsFullOpened] = useState(() => {
    const saved = localStorage.getItem("isFullOpenedNavbar");

    return saved !== null ? JSON.parse(saved) : true;
  });

  const openFullNavbar = () => {
    setIsFullOpened(true);
  };

  const closeFullNavbar = () => {
    setIsFullOpened(false);
  };

  useEffect(() => {
    localStorage.setItem("isFullOpenedNavbar", JSON.stringify(isFullOpened));
  }, [isFullOpened]);

  return (
    <FullOpenNavbarContext.Provider
      value={{
        isFullOpened,
        closeFullNavbar,
        openFullNavbar,
      }}
    >
      {children}
    </FullOpenNavbarContext.Provider>
  );
};

export default FullOpenNavbarContext;