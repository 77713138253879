import React from "react";

import { cn } from "../../lib/utils";

export const CardBox = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn("bg-white p-[20px] rounded-[20px] shadow-sm", className)}
    >
      {children}
    </div>
  );
};
